import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/solid";

const iframeId = "frase-iframe";
const rootId = "frase-answers-bot";

function BotDetails() {
  const { botId: botHash } = useParams<{ botId: string }>(); // Capture the botId from URL
  const [bot, setBot] = useState<any>(null);
  const [styles, setStyles] = useState<CSSProperties>({});
  const [showGreeting, setShowGreeting] = useState(true);
  const [greetingMessage, setGreetingMessage] = useState("");
  const [sendUrl, setSendUrl] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [position, setPosition] = useState<"left" | "right">("right");

  useEffect(() => {
    if (botHash) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/getBotByBotHash`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Indicates that we're sending a JSON body
        },
        body: JSON.stringify({ bot_hash: botHash }),
      }) // Change this URL to your API
        .then((response) => response.json())
        .then((data: any) => {
          setIsLoading(false);
          setBot(data);
        })
        .catch(() => setIsLoading(false));
    }
  }, [botHash]);

  useEffect(() => {
    if (bot) {
      const position = bot.miscInfo?.other?.botPosition || "right";
      setPosition(position);
    }
  }, [bot]);

  const botId = useMemo(() => {
    if (bot) {
      return bot.id;
    }
    return null;
  }, [bot]);

  const sendIframeParentUrl = useCallback(() => {
    const el = document.getElementById(iframeId);
    const iframe = el as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      console.debug("sending url to iframe: ", window.location.href);
      iframe.contentWindow.postMessage(
        {
          type: "URL_REQUEST",
          url: window.location.href,
        },
        "*"
      );
    }
  }, []);

  useEffect(() => {
    if (botId) {
      window.addEventListener("message", (event) => {
        const message: any = event.data;
        console.log(message);
        switch (message.type) {
          case 0:
            setStyles((message as any).styles);
            break;
          case 1:
            if ((message as any).config.showGreeting !== undefined) {
              const greeting = (message as any).config.showGreeting;
              setShowGreeting((state) =>
                greeting !== undefined ? greeting : state
              );
            }

            setGreetingMessage(
              (state) => (message as any).config.greetingMessage || state
            );
            break;
          case 3:
            setSendUrl(false);
            break;
          case 10:
            console.debug("Sending Parent Url");
            sendIframeParentUrl();
            break;
          case 11:
            console.debug("Setting Bot Position", (message as any).position);
            sendIframeParentUrl();
            break;
          default:
            console.debug("unhandled message", message);
        }
      });
    }
  }, [botId, sendIframeParentUrl]);

  const boxShadow = useMemo(() => {
    if (styles && styles.height === "100vh") {
      return "-5px 0 5px -5px rgba(0, 0, 0, 0.1)";
    }
    return undefined;
  }, [styles]);

  if (bot && bot.id) {
    return (
      <div>
        <p style={{ fontWeight: "bold", fontSize: "24px" }}>
          Frase Answers Sandbox
        </p>
        <p>{bot.name} Preview</p>
        <div
          id={rootId}
          style={{
            ...styles,
            overflow: "hidden",
          }}
        >
          <iframe
            title="Chat Bot"
            id="frase-iframe"
            src={`${process.env.REACT_APP_IFRAME_URL}/${bot.orgHash}/override${
              botId ? "?bot-id=" + botId : ""
            }`}
            style={{
              ...styles,
              border: "none",
              position: "fixed",
              backgroundColor: "white",
              zIndex: 10000,
              boxShadow: boxShadow,
              right: position === "right" ? "0px" : undefined,
              left: position === "left" ? "0px" : undefined,
              bottom: 0,
            }}
          ></iframe>
          {showGreeting && greetingMessage && (
            <div
              style={{
                position: "fixed",
                bottom: `calc(${styles.marginBottom} + ${styles.height} + 16px)`,
                right: position === "right" ? "18px" : undefined,
                left: position === "left" ? "18px" : undefined,
                backgroundColor: "#fff",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                zIndex: 10001,
                maxWidth: "300px",
                border: "1px solid #ddd",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "10px",
                  lineHeight: "16px",
                }}
              >
                {greetingMessage}
              </p>
              <button
                title="close greeting"
                onClick={() => setShowGreeting(false)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <XMarkIcon
                  style={{ height: "16px", width: "16px", color: "#bbb" }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else if (isLoading) {
    return <div>Loading Bot...</div>;
  } else {
    return <div>404</div>;
  }
}

export default BotDetails;
